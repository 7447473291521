import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import store from "./store";
import theme from "./theme";
import AppRoute from "./routes";
import reportWebVitals from "./reportWebVitals";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { translationEn } from "./translations/en";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

import "./index.scss";

const authConfig = {
  auth: {
    clientId: "af316871-8207-4ae5-8287-fe676f3f8e1e",
    authority: "https://login.microsoftonline.com/2e98a3d4-e818-4d87-be41-bd0cfc6a6ab8",
  },
  cache: {
    cacheLocation: "localStorage",
  },
};

const publicClientApplicationInstance = new PublicClientApplication(authConfig);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      ...translationEn,
    },
    lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false,
    },
  });

ReactDOM.render(
  <MsalProvider instance={publicClientApplicationInstance}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppRoute />
      </ThemeProvider>
    </Provider>
  </MsalProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
