import React from "react";
import { useHistory } from "react-router-dom";
import { Logo } from "../../assets/images";
import { useMsal } from "@azure/msal-react";
import { Button } from "@material-ui/core";

import "./unauthenticated.scss";

const Unauthenticated = () => {
  const { instance, accounts, inProgress } = useMsal();
  const history = useHistory();

  async function handleLogin() {
    await instance.loginPopup();
  }

  React.useEffect(() => {
    if (accounts.length > 0) {
      history.goBack();
    }
  }, [accounts, history]);

  return (
    <div className="container">
      <div className="header">
        <Logo />
      </div>
      <h1>
        Unauthenticated <span>:(</span>
      </h1>
      <p>We are sorry, but you are not authorized to view this page.</p>
      <div className="login-prompt">
        <Button variant="contained" color="primary" onClick={handleLogin} disabled={inProgress === "login"}>
          {inProgress === "login" ? "Loading" : "Login"}
        </Button>
      </div>

      <i>401</i>
    </div>
  );
};

export default Unauthenticated;
